import SelectInput from '../common/SelectInput';
import TextInput from '../common/TextInput';
import FowButton from '../common/FowButton';
import React, { useEffect, useState } from 'react';
import {
  IWillCreatorExportField,
  IWillCreatorSearchField,
} from '../../datatypes/WillCreator';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchStates } from '../../redux/slices/locationSlice';
import SelectCountyInput from '../common/SelectCountyInput';
import { ILocationPayload } from '../../datatypes/Location';
import RadioGroupInput from '../common/RadioGroupInput';
import {
  charityOptions,
  genderOptions,
  hasChildrenOptions,
  maritalStatusOptions,
} from 'src/constants/User';
import { pageCodes } from 'src/constants/QuestionnaireCodes';
import { fetchQuestionsByPage } from 'src/redux/slices/questionSlice';
import { IQuestionPayload } from 'src/datatypes/Question';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { ICharityPayload } from 'src/datatypes/Charity';
import { fetchCharities } from 'src/redux/slices/charitySlice';
import { OptionType } from 'src/datatypes/CommonTypes';

interface IProps {
  exportWill: IWillCreatorExportField;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onExportWill: () => void;
  resetExportWillFilter: () => void;
  isFilterApplied: boolean;
  errors?: IWillCreatorSearchField;
}

const ExportForm = ({
  exportWill,
  handleInputChange,
  onExportWill,
  resetExportWillFilter,
  isFilterApplied,
  errors,
}: IProps) => {
  const dispatch = useAppDispatch();

  const [options, setOptions] = useState<OptionType<string | number>[]>([]);

  const stateOptions = useAppSelector<ILocationPayload['states']['data']>(
    (state) => state.location.states.data
  );

  const stateLoading = useAppSelector<
    ILocationPayload['states']['stateLoading']
  >((state) => state.location.states.stateLoading);

  const { pageQuestions } = useAppSelector<IQuestionPayload>(
    (state) => state.questions
  );

  const { charityList } = useAppSelector<ICharityPayload>(
    (state) => state.charities
  );

  useEffect(() => {
    dispatch(fetchStates());

    // Fetch asset options
    if (!pageQuestions?.data)
      dispatch(fetchQuestionsByPage(pageCodes.assetInfoPage));

    // If the charity list is empty, fetch the charities
    if (charityList?.data?.data?.length === 0) dispatch(fetchCharities({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (charityList?.data?.data) {
      const charities = charityList.data.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setOptions(charities);
    }
    // eslint-disable-next-line
  }, [charityList]);

  return (
    <div className="will-export-form">
      <div className="row">
        <div className="col-lg-4">
          <RadioGroupInput
            name="gender"
            value={exportWill.gender}
            label="Gender"
            options={genderOptions}
            onChange={(e) => handleInputChange(e)}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <RadioGroupInput
            name="marital_status"
            value={exportWill.marital_status}
            label="Marital Status"
            options={maritalStatusOptions}
            onChange={(e) => handleInputChange(e)}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <RadioGroupInput
            name="has_children"
            value={exportWill.has_children}
            label="Has Children"
            options={hasChildrenOptions}
            onChange={(e) => handleInputChange(e)}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="city"
            placeholder="City"
            value={exportWill.city}
            onChange={(e) => handleInputChange(e)}
            label="City"
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <SelectInput
            name="state_id"
            placeholder="Select State"
            value={exportWill.state_id}
            optionLabel="state_name"
            optionValue="id"
            options={stateOptions}
            isLoading={stateLoading}
            handleSelectChange={(e) => handleInputChange(e)}
            label="State"
          />
        </div>
        <div className="col-lg-4">
          <SelectCountyInput
            name="county_id"
            placeholder="Select County"
            value={exportWill.county_id}
            selectedState={exportWill.state_id}
            label="County"
            handleSelectChange={(e) => handleInputChange(e)}
            isClearable
          />
        </div>
        <div className="col-lg-4">
          <SelectInput
            name="assets_value"
            placeholder="Assets Value"
            value={exportWill.assets_value}
            optionLabel="label"
            optionValue="value"
            options={
              pageQuestions?.data?.[0]?.inputs[questionInputKeys.ASSETS_VALUE]
                ?.options || []
            }
            handleSelectChange={(e) => handleInputChange(e)}
            label="Assets Value"
            isLoading={pageQuestions?.loading}
          />
        </div>
        <div className="col-lg-4">
          <RadioGroupInput
            name="charity"
            value={exportWill.charity}
            label="Charity"
            options={charityOptions}
            onChange={(e) => handleInputChange(e)}
            isRequired={false}
          />
        </div>
        {exportWill?.charity === 'specific_charity' && (
          <div className="col-lg-4">
            <SelectInput
              name="charity_ids"
              placeholder="Charity"
              value={exportWill.charity_ids}
              optionLabel="label"
              optionValue="value"
              options={options}
              handleSelectChange={(e) => handleInputChange(e)}
              label=""
              errorMessage={errors?.charity}
              isMulti={true}
            />
          </div>
        )}
      </div>
      <div className="row mb-3">
        <div className="col-lg-12">
          <div className="d-flex gap-3 justify-content-between w-100">
            <FowButton variant="success" onClick={onExportWill}>
              Download
            </FowButton>
            {isFilterApplied && (
              <FowButton
                variant="outline-secondary"
                onClick={resetExportWillFilter}
              >
                <i className="fa fa-undo mr-2"></i> Reset
              </FowButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportForm;
